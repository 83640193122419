import LaunchIcon from "@mui/icons-material/Launch";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid2";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFetchIsfpDocumentUrl } from "../../../../../hooks/useFetchIsfpDocument";
import { IsfpState, Product } from "../../../../../types/cockpit/types";
import { SchnellcheckSource } from "../../../../../types/Schnellcheck";
import { DisplayBubbleFlowRenovationDialog } from "../DisplayBubbleFlowRenovationDialog";
import { DisplayBubbleFlowSchnellcheckDialog } from "../DisplayBubbleFlowSchnellcheckDialog";
import { DisplayEmbeddedDialog } from "../DisplayEmbeddedDialog";
import { DisplaySchnellcheckDialog } from "../DisplaySchnellcheckDialog";
import { RowProps } from "../LeadList";
import { EXAMPLE_ISFP_ID_PREFIX, SAMPLE_ISFP_DOCUMENT_URL } from "../PlaceholderLeadData";

export default function IsfpWorkflow({ row, openInviteLeadModal }: { row: RowProps; openInviteLeadModal: (product: Product) => void }) {
  const navigate = useNavigate();
  const [actionTriggered, setActionTriggered] = useState(false);

  const renderStates = () => {
    return (
      <Stack direction={"row"} alignItems={"center"} alignSelf={"flex-end"} onClick={() => setActionTriggered(true)} sx={{ cursor: "pointer" }}>
        <Stack>
          <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Aktueller Status</Typography>
          <Chip label={IsfpState.toString(row.isfp.state)} />
        </Stack>
        {![IsfpState.FINAL, IsfpState.OFFER_REJECTED].includes(row.isfp.state) && <NavigateNextIcon sx={{ alignSelf: "flex-end", mb: "0.25rem" }} />}
        {![IsfpState.FINAL, IsfpState.OFFER_REJECTED].includes(row.isfp.state) && (
          <Stack>
            <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Nächster Schritt</Typography>
            {IsfpState.happyPathNext(row.isfp.state) && (
              <Chip variant='outlined' sx={{ color: "text.secondary" }} label={IsfpState.toString(IsfpState.happyPathNext(row.isfp.state)!)} />
            )}
          </Stack>
        )}
      </Stack>
    );
  };

  if (row.isfp.state === IsfpState.START) {
    return (
      <Grid container alignItems={"flex-end"}>
        <Grid size={1}>
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontWeight: "fontWeightBold",
              width: "12rem",
              alignSelf: "center",
            }}
          >
            iSFP
          </Typography>
        </Grid>
        <Grid size={3}>
          <Button
            variant='contained'
            sx={{
              fontSize: "0.7rem",
            }}
            onClick={() => openInviteLeadModal(Product.isfp)}
          >
            {IsfpState.toString(row.isfp.state)}
          </Button>
        </Grid>
        <Grid size={8}>
          <Typography
            sx={{
              fontSize: "0.7rem",
              color: "text.secondary",
            }}
          >
            Lade hier deine Kunden zum kostenlosen Schnellcheck, der Vorstufe zum Sanierungsfahrplan ein. Die Kunden können nach dem Ausfüllen des Checks ein
            kostenloses Erstgespräch vereinbaren und ein unverbindliches Angebot anfragen.
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Stack direction={"row"} spacing={5} alignItems={"center"}>
        <Box pt={2}>
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontWeight: "fontWeightBold",
              width: "12rem",
            }}
          >
            iSFP
          </Typography>
        </Box>
        {renderStates()}
        <Box pt={1}>
          <IsfpAction row={row} actionTriggered={actionTriggered} setActionTriggered={setActionTriggered} />
        </Box>
        <IconButton
          sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
          onClick={() => {
            navigate(`/cockpit/leads/${row.id}/isfp?filter=${encodeURIComponent(row.email)}`);
          }}
        >
          <MoreHorizOutlinedIcon />
        </IconButton>
      </Stack>
    );
  }
}

interface IsfpActionProps {
  row: RowProps;
  actionTriggered: boolean;
  setActionTriggered: Dispatch<SetStateAction<boolean>>;
}

function IsfpAction({ row, actionTriggered, setActionTriggered }: IsfpActionProps) {
  const [downloadDocument, setDownloadDocument] = useState(false);
  const [isSchnellcheckOpen, setSchnellcheckOpen] = useState(false);
  const [isRenovationsOpen, setRenovationsOpen] = useState(false);
  const { fetchIsfpDocumentUrl, isfpDocumentLink } = useFetchIsfpDocumentUrl();

  useEffect(() => {
    if (row.isfp.id?.startsWith(EXAMPLE_ISFP_ID_PREFIX)) {
      return; // ignore doc fetch when loading example data.
    }
    if (row.isfp.id) {
      fetchIsfpDocumentUrl(row.isfp.id);
    }
  }, [fetchIsfpDocumentUrl, row.isfp.id]);

  useEffect(() => {
    if (actionTriggered) {
      if ([IsfpState.STATUS_QUO_FINISHED].includes(row.isfp.state)) {
        setSchnellcheckOpen(true);
      }
      if ([IsfpState.RENOVATIONS_FINISHED].includes(row.isfp.state)) {
        setRenovationsOpen(true);
      }
      if (row.isfp.state === IsfpState.FINAL && row.isfp.id) {
        setDownloadDocument(true);
      }
    }
  }, [actionTriggered, row.isfp.id, row.isfp.state]);

  useEffect(() => {
    if (downloadDocument && row.isfp.id?.startsWith(EXAMPLE_ISFP_ID_PREFIX)) {
      window.open(SAMPLE_ISFP_DOCUMENT_URL, "_blank", "noopener,noreferrer");
      setActionTriggered(false);
      setDownloadDocument(false);
    }
    if (downloadDocument && isfpDocumentLink) {
      window.open(isfpDocumentLink.url, "_blank", "noopener,noreferrer");
      setActionTriggered(false);
      setDownloadDocument(false);
    }
  }, [downloadDocument, isfpDocumentLink, row.isfp.id, setActionTriggered]);

  const closeSchnellcheckModal = () => {
    setSchnellcheckOpen(false);
    setActionTriggered(false);
  };

  const closeRenovationsModal = () => {
    setRenovationsOpen(false);
    setActionTriggered(false);
  };

  if ([IsfpState.STATUS_QUO_FINISHED].includes(row.isfp.state)) {
    return (
      <Box>
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
          }}
          onClick={() => setSchnellcheckOpen(true)}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
        {row.isfp.bubbleFlow && (
          <DisplayBubbleFlowSchnellcheckDialog bubbleFlow={row.isfp.bubbleFlow} open={isSchnellcheckOpen} handleClose={closeSchnellcheckModal} />
        )}
        {row.isfp.schnellcheck && row.isfp.schnellcheck.source === SchnellcheckSource.standalone && (
          <DisplaySchnellcheckDialog schnellcheck={row.isfp.schnellcheck} open={isSchnellcheckOpen} handleClose={closeSchnellcheckModal} />
        )}
        {row.isfp.schnellcheck && row.isfp.schnellcheck.source === SchnellcheckSource.embedded && (
          <DisplayEmbeddedDialog schnellcheck={row.isfp.schnellcheck} open={isSchnellcheckOpen} handleClose={closeSchnellcheckModal} />
        )}
      </Box>
    );
  } else if ([IsfpState.RENOVATIONS_FINISHED].includes(row.isfp.state)) {
    return (
      <Box>
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
          }}
          onClick={() => setRenovationsOpen(true)}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
        <DisplayBubbleFlowRenovationDialog bubbleFlow={row.isfp.bubbleFlow} open={isRenovationsOpen} handleClose={closeRenovationsModal} />
      </Box>
    );
  } else if (row.isfp.state === IsfpState.FINAL && row.isfp.id) {
    return (
      <Link
        component='button'
        underline={"always"}
        onClick={() => setDownloadDocument(true)}
        sx={{
          color: "text.primary",
          fontSize: "0.9rem",
          textDecorationColor: "inherit",
        }}
      >
        <PictureAsPdfIcon
          sx={{
            fontSize: "1.2rem",
            verticalAlign: "middle",
          }}
        />
        iSFP einsehen
      </Link>
    );
  } else {
    return <></>;
  }
}
