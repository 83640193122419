import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, Link, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { OPState, Product } from "../../../../../types/cockpit/types";
import { RowProps } from "../LeadList";

export default function OPWorkflow({ row, openInviteLeadModal }: { row: RowProps; openInviteLeadModal: (product: Product) => void }) {
  const renderStates = () => {
    if (row.op.state === OPState.START) {
      return (
        <Tooltip title='Für Kauf und Verkauf von Immobilien: Das Sanierungsgutachten zeigt auf einer Seite, wie viel die Sanierung voraussichtlich kostet und was sie bringt.'>
          <Button
            variant='contained'
            sx={{
              fontSize: "0.7rem",
            }}
            onClick={() => openInviteLeadModal(Product.onePager)}
          >
            {OPState.toString(row.op.state)}
          </Button>
        </Tooltip>
      );
    }

    return (
      <>
        <Stack direction={"row"} alignItems={"center"} alignSelf={"flex-end"}>
          <Stack>
            <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Aktueller Status</Typography>
            <Chip label={OPState.toString(row.op.state)} />
          </Stack>
          {row.op.state !== OPState.FINAL && <NavigateNextIcon sx={{ alignSelf: "flex-end", mb: "0.25rem" }} />}
          {row.op.state !== OPState.FINAL && (
            <Stack>
              <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Nächster Schritt</Typography>
              <Chip label={OPState.toString(row.cc.state + 1)} />
            </Stack>
          )}
        </Stack>
        {row.op.state === OPState.FINAL && !row.op.doubleFiles && (
          <Box pt={1}>
            <Link
              href={row.op.bubbleCdnUrl}
              underline={"always"}
              target='_blank'
              sx={{
                color: "text.primary",
                fontSize: "0.9rem",
                textDecorationColor: "inherit",
              }}
            >
              <PictureAsPdfIcon
                sx={{
                  fontSize: "1.2rem",
                  verticalAlign: "middle",
                }}
              />
              Sanierungsgutachten einsehen
            </Link>
          </Box>
        )}
        {row.op.state === OPState.FINAL && row.op.doubleFiles && (
          <Box pt={1}>
            <Link
              href={row.op.sanierungscheckKfw}
              underline={"always"}
              target='_blank'
              sx={{
                color: "text.primary",
                fontSize: "0.9rem",
                textDecorationColor: "inherit",
              }}
            >
              <PictureAsPdfIcon
                sx={{
                  fontSize: "1.2rem",
                  verticalAlign: "middle",
                }}
              />
              KFW-Version einsehen
            </Link>
            <Link
              href={row.op.sanierungscheckSanierungspflicht}
              underline={"always"}
              target='_blank'
              sx={{
                color: "text.primary",
                fontSize: "0.9rem",
                textDecorationColor: "inherit",
              }}
            >
              <PictureAsPdfIcon
                sx={{
                  fontSize: "1.2rem",
                  verticalAlign: "middle",
                }}
              />
              Sanierungspflicht-Version einsehen
            </Link>
          </Box>
        )}
      </>
    );
  };

  return (
    <Stack direction={"row"} spacing={5} alignItems={"center"}>
      <Box pt={2}>
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontWeight: "fontWeightBold",
            width: "12rem",
          }}
        >
          Sanierungsgutachten
        </Typography>
      </Box>
      {renderStates()}
    </Stack>
  );
}
