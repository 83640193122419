import { format, subMonths } from "date-fns";
import { ConsumptionCertificateState } from "../../../types/ConsumptionCertificateState";
import {
  Purpose,
  getPurpose,
  BuildingType,
  getBuildingType,
  GermanState,
  getGermanState,
  Month,
  getMonth,
  Reforms,
} from "./ConsumptionCertificateRequestEnums";
import { includesHouseNumber } from "../../../components/common/NovoAddressAutocomplete";

export default class ConsumptionCertificateRequest {
  sections = ["Gebäude", "Allgemein", "Kühlung", "Heizung", "Warmwasserdaten"];
  buildingData: BuildingData;
  generalData: GeneralData;
  coolingData: CoolingData;
  heatingData: HeatingData;
  hotWaterData: HotWaterData;

  constructor() {
    this.buildingData = new BuildingData();
    this.generalData = new GeneralData();
    this.coolingData = new CoolingData();
    this.heatingData = new HeatingData();
    this.hotWaterData = new HotWaterData(this.heatingData.heatingSystemOptions);
  }

  isValid(): boolean {
    return this.buildingData.isValid() && this.generalData.isValid() && this.coolingData.isValid() && this.heatingData.isValid() && this.hotWaterData.isValid();
  }

  validate(): boolean {
    return (
      this.buildingData.validate() && this.generalData.validate() && this.coolingData.validate() && this.heatingData.validate() && this.hotWaterData.validate()
    );
  }

  toConsumptionCertificateState(): ConsumptionCertificateState {
    return {
      reason: getPurpose(this.buildingData.purpose),
      typeOfBuilding: getBuildingType(this.buildingData.type),
      area: Number.parseInt(this.buildingData.surface),
      yearOfConstruction: Number.parseInt(this.buildingData.constructionYear),
      reform: this.buildingData.reform,
      numberOfApartments: Number.parseInt(this.buildingData.houseCount),
      address: this.buildingData.address,
      postalCode: this.buildingData.postalCode,
      location: this.buildingData.town,
      bundesland: getGermanState(this.buildingData.state),
      photo: this.buildingData.photo,
      hasHeatedBasement: this.buildingData.hasHeatedBasement,

      heatingSystemType: this.heatingData.heatingSystem,
      yearOfHeatingInstalation: Number.parseInt(this.heatingData.installationYear),
      endMonthOfBill: getMonth(this.heatingData.readingMonth),
      yearOfBill: Number.parseInt(this.heatingData.readingYear),
      verbrauch1: Number.parseInt(this.heatingData.readingValue),
      verbrauch2: Number.parseInt(this.heatingData.readingValueYearBefore),
      verbrauch3: Number.parseInt(this.heatingData.readingValueTwoYearsBefore),
      secondaryHeatingSystemType: this.heatingData.secondaryHeatingSystemType,
      secondaryYearOfHeatingInstalation: Number.parseInt(this.heatingData.secondaryYearOfHeatingInstalation),
      secondaryVerbrauch1: Number.parseInt(this.heatingData.secondaryReadingValue),
      secondaryVerbrauch2: Number.parseInt(this.heatingData.secondaryReadingValueYearBefore),
      secondaryVerbrauch3: Number.parseInt(this.heatingData.secondaryReadingValueTwoYearsBefore),

      ventilationType: this.generalData.ventilationTypes,
      coolingSystems: this.coolingData.getSelectedSystems(),
      coolingSystemsNumber: this.coolingData.number,
      coolingSystemsNominalOutput: this.coolingData.nominalOutput,
      coolingSystemsNextInspection: this.coolingData.nextInspection ? format(this.coolingData.nextInspection, "yyyy-MM-dd") : undefined,
      coolingSystemsHasBuildingAutomation: this.coolingData.hasBuildingAutomation,
      renewable: this.generalData.renewableEnergySources,
      renewableUsage: this.generalData.renewableEnergyUsage,
      renovationMeasures: this.generalData.renovationMeasures,

      hotWaterSystems: [...this.hotWaterData.systems].map((system) => {
        delete system.error;
        delete system.centralizedError;
        delete system.partOfHeatingError;
        return system;
      }),
    };
  }

  static deepClone(original: ConsumptionCertificateRequest) {
    const certificate = new ConsumptionCertificateRequest();
    certificate.buildingData.purpose = original.buildingData.purpose;
    certificate.buildingData.purposeError = original.buildingData.purposeError;
    certificate.buildingData.type = original.buildingData.type;
    certificate.buildingData.typeError = original.buildingData.typeError;
    certificate.buildingData.reform = original.buildingData.reform;
    certificate.buildingData.reformError = original.buildingData.reformError;
    certificate.buildingData.houseCount = original.buildingData.houseCount;
    certificate.buildingData.houseCountError = original.buildingData.houseCountError;
    certificate.buildingData.constructionYear = original.buildingData.constructionYear;
    certificate.buildingData.constructionYearError = original.buildingData.constructionYearError;
    certificate.buildingData.surface = original.buildingData.surface;
    certificate.buildingData.surfaceError = original.buildingData.surfaceError;
    certificate.buildingData.address = original.buildingData.address;
    certificate.buildingData.autocompleteAddress = original.buildingData.autocompleteAddress;
    certificate.buildingData.addressError = original.buildingData.addressError;
    certificate.buildingData.postalCode = original.buildingData.postalCode;
    certificate.buildingData.postalCodeError = original.buildingData.postalCodeError;
    certificate.buildingData.postalCodeUnknown = original.buildingData.postalCodeUnknown;
    certificate.buildingData.town = original.buildingData.town;
    certificate.buildingData.townError = original.buildingData.townError;
    certificate.buildingData.state = original.buildingData.state;
    certificate.buildingData.stateError = original.buildingData.stateError;
    certificate.buildingData.photo = original.buildingData.photo;
    certificate.buildingData.photoError = original.buildingData.photoError;
    certificate.buildingData.isEmptyProperty = original.buildingData.isEmptyProperty;
    certificate.buildingData.hasHeatedBasement = original.buildingData.hasHeatedBasement;

    certificate.generalData.ventilationTypes = [...original.generalData.ventilationTypes];
    certificate.generalData.ventilationError = original.generalData.ventilationError;
    certificate.generalData.renewableEnergySources = [...original.generalData.renewableEnergySources];
    certificate.generalData.renewableEnergyUsage = [...original.generalData.renewableEnergyUsage];
    certificate.generalData.renewableEnergyUsageError = original.generalData.renewableEnergyUsageError;
    certificate.generalData.renovationMeasures = original.generalData.renovationMeasures;

    certificate.coolingData.coolingTypes["Gelieferte Kälte"] = original.coolingData.coolingTypes["Gelieferte Kälte"];
    certificate.coolingData.coolingTypes["Kühlung aus Strom"] = original.coolingData.coolingTypes["Kühlung aus Strom"];
    certificate.coolingData.coolingTypes["Kühlung aus Wärme"] = original.coolingData.coolingTypes["Kühlung aus Wärme"];
    certificate.coolingData.coolingTypes["Passive Kühlung"] = original.coolingData.coolingTypes["Passive Kühlung"];
    certificate.coolingData.number = original.coolingData.number;
    certificate.coolingData.numberError = original.coolingData.numberError;
    certificate.coolingData.nextInspection = original.coolingData.nextInspection;
    certificate.coolingData.nextInspectionError = original.coolingData.nextInspectionError;
    certificate.coolingData.hasBuildingAutomation = original.coolingData.hasBuildingAutomation;
    certificate.coolingData.nominalOutput = original.coolingData.nominalOutput;
    certificate.coolingData.nominalOutputError = original.coolingData.nominalOutputError;

    certificate.heatingData.installationYear = original.heatingData.installationYear;
    certificate.heatingData.installationYearError = original.heatingData.installationYearError;
    certificate.heatingData.heatingSystem = original.heatingData.heatingSystem;
    certificate.heatingData.heatingSystemError = original.heatingData.heatingSystemError;
    certificate.heatingData.readingMonth = original.heatingData.readingMonth;
    certificate.heatingData.readingMonthError = original.heatingData.readingMonthError;
    certificate.heatingData.readingYear = original.heatingData.readingYear;
    certificate.heatingData.readingYearError = original.heatingData.readingYearError;
    certificate.heatingData.readingValue = original.heatingData.readingValue;
    certificate.heatingData.readingValueError = original.heatingData.readingValueError;
    certificate.heatingData.readingValueYearBefore = original.heatingData.readingValueYearBefore;
    certificate.heatingData.readingValueYearBeforeError = original.heatingData.readingValueYearBeforeError;
    certificate.heatingData.readingValueTwoYearsBefore = original.heatingData.readingValueTwoYearsBefore;
    certificate.heatingData.readingValueTwoYearsBeforeError = original.heatingData.readingValueTwoYearsBeforeError;
    certificate.heatingData.secondaryHeatingSystemType = original.heatingData.secondaryHeatingSystemType;
    certificate.heatingData.secondaryYearOfHeatingInstalation = original.heatingData.secondaryYearOfHeatingInstalation;
    certificate.heatingData.secondaryInstallationYearError = original.heatingData.secondaryInstallationYearError;
    certificate.heatingData.secondaryReadingValue = original.heatingData.secondaryReadingValue;
    certificate.heatingData.secondaryReadingValueError = original.heatingData.secondaryReadingValueError;
    certificate.heatingData.secondaryReadingValueYearBefore = original.heatingData.secondaryReadingValueYearBefore;
    certificate.heatingData.secondaryReadingValueYearBeforeError = original.heatingData.secondaryReadingValueYearBeforeError;
    certificate.heatingData.secondaryReadingValueTwoYearsBefore = original.heatingData.secondaryReadingValueTwoYearsBefore;
    certificate.heatingData.secondaryReadingValueTwoYearsBeforeError = original.heatingData.secondaryReadingValueTwoYearsBeforeError;
    certificate.heatingData.months = original.heatingData.months;

    certificate.hotWaterData.systems = [...original.hotWaterData.systems];
    certificate.hotWaterData.error = original.hotWaterData.error;

    return certificate;
  }

  save(): void {
    localStorage.setItem("consumption-certificate-request", JSON.stringify(this));
  }

  static load(): ConsumptionCertificateRequest | undefined {
    const item = localStorage.getItem("consumption-certificate-request");
    if (!item) {
      return;
    }
    const data = JSON.parse(item);
    const certificate = new ConsumptionCertificateRequest();
    Object.assign(certificate.buildingData, data.buildingData);
    // JSON parser will return empty object, which is not a falsy value
    certificate.buildingData.photo = null;
    Object.assign(certificate.generalData, data.generalData);
    Object.assign(certificate.coolingData, data.coolingData);
    Object.assign(certificate.heatingData, data.heatingData);
    Object.assign(certificate.hotWaterData, data.hotWaterData);
    return certificate;
  }

  cleanUp(): void {
    localStorage.removeItem("consumption-certificate-request");
  }
}

class BuildingData {
  purpose = "";
  purposeError: boolean;
  purposeOptions = Object.keys(Purpose);
  type = "";
  typeError: boolean;
  typeOptions = Object.keys(BuildingType);
  reform = "";
  reformError: boolean;
  reformOptions = Object.keys(Reforms);
  houseCount = "";
  houseCountError: boolean;
  constructionYear = "";
  constructionYearError: boolean;
  maxConstructionYear = new Date().getFullYear() - 3;
  surface = "";
  surfaceError: boolean;
  autocompleteAddress: google.maps.places.AutocompletePrediction | null = null;
  address = "";
  addressError = "";
  postalCode = "";
  postalCodeError: boolean;
  postalCodeUnknown: boolean;
  town = "";
  townError: boolean;
  state = "";
  stateOptions = Object.keys(GermanState);
  stateError: boolean;
  photo: File | null | undefined;
  photoError: boolean;
  isEmptyProperty = false;
  hasHeatedBasement = false;

  isValid(): boolean {
    return !(
      this.purposeError ||
      this.typeError ||
      this.houseCountError ||
      this.constructionYearError ||
      this.surfaceError ||
      this.addressError ||
      this.postalCodeError ||
      this.postalCodeUnknown ||
      this.townError ||
      this.stateError ||
      this.photoError
    );
  }

  validate(): boolean {
    this.purposeError = !this.purpose;
    this.typeError = !this.type;
    this.houseCountError = !this.houseCount || Number.parseInt(this.houseCount) <= 0;
    this.constructionYearError =
      !this.constructionYear || Number.parseInt(this.constructionYear) > this.maxConstructionYear || Number.parseInt(this.constructionYear) < 1700;
    this.surfaceError = !this.surface || Number.parseInt(this.surface) <= 0;
    this.postalCodeError = !this.postalCode || !this.postalCode.match(/^[0-9]{5}$/);
    this.townError = !this.town;
    this.stateError = !this.state;
    this.photoError = !this.photo;

    if (!this.address) {
      this.addressError = "Pflichtfeld";
    } else if (!includesHouseNumber(this.autocompleteAddress)) {
      this.addressError = "Geben Sie die Hausnummer an";
    } else {
      this.addressError = "";
    }

    return this.isValid();
  }
}

class GeneralData {
  ventilationTypes = new Array<string>();
  ventilationOptions = new Array<string>();
  ventilationError = false;
  renewableEnergySources = new Array<string>();
  renewableEnergyOptions = new Array<string>();
  isEmptyProperty = false;
  renewableEnergyUsage = new Array<string>();
  renewableEnergyUsageError = false;
  renewableEnergyUsageOptions = new Array<string>();
  renovationMeasures = "";

  constructor() {
    this.ventilationOptions.push("Fensterlüftung");
    this.ventilationOptions.push("Schachtlüftung");
    this.ventilationOptions.push("Lüftungsanlage mit Wärmerückgewinnung");
    this.ventilationOptions.push("Lüftungsanlage ohne Wärmerückgewinnung");

    this.renewableEnergyOptions.push("Solarthermie");
    this.renewableEnergyOptions.push("Photovoltaik");
    this.renewableEnergyOptions.push("Wärmepumpe");
    this.renewableEnergyOptions.push("Biomasse");

    this.renewableEnergyUsageOptions.push("Heizung");
    this.renewableEnergyUsageOptions.push("Warmwasser");
    this.renewableEnergyUsageOptions.push("Strom");
  }

  isValid(): boolean {
    return !this.renewableEnergyUsageError && !this.ventilationError;
  }

  validate(): boolean {
    this.renewableEnergyUsageError = this.renewableEnergySources.length > 0 && this.renewableEnergyUsage.length === 0;
    this.ventilationError = this.ventilationTypes.length === 0;
    return this.isValid();
  }
}

class CoolingData {
  coolingOptions = ["Kühlung aus Strom", "Gelieferte Kälte", "Kühlung aus Wärme", "Passive Kühlung"] as const;
  coolingTypes: { [key: string]: { selected: boolean; surface?: string; error: boolean } };
  number: string;
  numberError: boolean;
  nominalOutput: string;
  nominalOutputError: boolean;
  nextInspection: Date;
  nextInspectionError: boolean;
  hasBuildingAutomation: boolean;

  constructor() {
    this.coolingTypes = {
      "Kühlung aus Strom": { selected: false, error: false },
      "Gelieferte Kälte": { selected: false, error: false },
      "Kühlung aus Wärme": { selected: false, error: false },
      "Passive Kühlung": { selected: false, error: false },
    };
  }

  isActiveCoolingSelected(): boolean {
    return this.coolingTypes["Kühlung aus Strom"].selected || this.coolingTypes["Kühlung aus Wärme"].selected || this.coolingTypes["Gelieferte Kälte"].selected;
  }

  isValid(): boolean {
    return !(
      this.coolingTypes["Gelieferte Kälte"].error ||
      this.coolingTypes["Kühlung aus Strom"].error ||
      this.coolingTypes["Kühlung aus Wärme"].error ||
      this.numberError ||
      this.nominalOutputError ||
      this.nextInspectionError
    );
  }

  validate(): boolean {
    this.coolingTypes["Gelieferte Kälte"].error = this.coolingTypes["Gelieferte Kälte"].selected && !this.coolingTypes["Gelieferte Kälte"].surface;
    this.coolingTypes["Kühlung aus Strom"].error = this.coolingTypes["Kühlung aus Strom"].selected && !this.coolingTypes["Kühlung aus Strom"].surface;
    this.coolingTypes["Kühlung aus Wärme"].error = this.coolingTypes["Kühlung aus Wärme"].selected && !this.coolingTypes["Kühlung aus Wärme"].surface;
    this.numberError = this.isActiveCoolingSelected() && (!this.number || +this.number <= 0);
    this.nominalOutputError = this.isActiveCoolingSelected() && (!this.nominalOutput || +this.nominalOutput <= 0);
    this.nextInspectionError =
      this.isActiveCoolingSelected() &&
      (+this.nominalOutput > 70 || (+this.nominalOutput > 12 && !this.hasBuildingAutomation)) &&
      (!this.nextInspection || this.nextInspection.getTime() < Date.now());
    return this.isValid();
  }

  getSelectedSystems(): { system: string; surface?: number }[] {
    return Object.keys(this.coolingTypes)
      .filter((key: (typeof this.coolingOptions)[number]) => !!this.coolingTypes[key].selected)
      .map((key: (typeof this.coolingOptions)[number]) => ({ system: key, surface: Number.parseInt(this.coolingTypes[key].surface ?? "") }));
  }
}

class HeatingData {
  installationYear = "";
  installationYearError: boolean;
  heatingSystem = "";
  heatingSystemError: boolean;
  heatingSystemOptions = new Array<string>();
  readingMonth = "";
  readingMonthError: boolean;
  months = Object.keys(Month);
  readingYear = "";
  readingYearError: boolean;
  years = new Array<string>();
  readingValue = "";
  readingValueError: boolean;
  readingValueYearBefore = "";
  readingValueYearBeforeError: boolean;
  readingValueTwoYearsBefore = "";
  readingValueTwoYearsBeforeError: boolean;
  secondaryHeatingSystemType = "";
  secondaryYearOfHeatingInstalation = "";
  secondaryInstallationYearError: boolean;
  secondaryReadingValue = "";
  secondaryReadingValueError: boolean;
  secondaryReadingValueYearBefore = "";
  secondaryReadingValueYearBeforeError: boolean;
  secondaryReadingValueTwoYearsBefore = "";
  secondaryReadingValueTwoYearsBeforeError: boolean;

  constructor() {
    this.heatingSystemOptions.push("Heizöl in kWh Heizwert");
    this.heatingSystemOptions.push("Heizöl in kWh Brennwert");
    this.heatingSystemOptions.push("Erdgas in kWh Heizwert");
    this.heatingSystemOptions.push("Erdgas in kWh Brennwert");
    this.heatingSystemOptions.push("Flüssiggas in kWh Heizwert");
    this.heatingSystemOptions.push("Steinkohle in kWh Heizwert");
    this.heatingSystemOptions.push("Braunkohle in kWh Heizwert");
    this.heatingSystemOptions.push("Biogas in kWh Heizwert");
    this.heatingSystemOptions.push("Biogas in kWh Brennwert");
    this.heatingSystemOptions.push("Biogas, gebäudenah erzeugt in kWh Heizwert");
    this.heatingSystemOptions.push("Biogas, gebäudenah erzeugt in kWh Brennwert");
    this.heatingSystemOptions.push("biogenes Flüssiggas in kWh Heizwert");
    this.heatingSystemOptions.push("Bioöl in kWh Heizwert");
    this.heatingSystemOptions.push("Bioöl in kWh Brennwert");
    this.heatingSystemOptions.push("Bioöl, gebäudenah erzeugt in kWh Heizwert");
    this.heatingSystemOptions.push("Bioöl, gebäudenah erzeugt in kWh Brennwert");
    this.heatingSystemOptions.push("Holz in kWh Heizwert");
    this.heatingSystemOptions.push("Holz in kWh Brennwert");
    this.heatingSystemOptions.push("Strom netzbezogen in kWh");
    this.heatingSystemOptions.push("Strom gebäudenah erzeugt (aus Photovoltaik, Windkraft) in kWh");
    this.heatingSystemOptions.push("Verdrängungsstrommix für KWK in kWh");
    this.heatingSystemOptions.push("Wärme (Erdwärme, Geothermie, Solarthermie, Umgebungswärme) in kWh");
    this.heatingSystemOptions.push("Kälte (Erdkälte, Umgebungskälte) in kWh");
    this.heatingSystemOptions.push("Abwärme aus Prozessen (prod) in kWh");
    this.heatingSystemOptions.push("Abwärme aus Prozessen (out) in kWh");
    this.heatingSystemOptions.push("Wärme aus KWK, gebäudeintegriert oder gebäudenah in kWh");
    this.heatingSystemOptions.push("Wärme aus Verbrennung von Siedlungsabfällen in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus KWK, fossiler Brennstoff (Stein_/Braunkohle) bzw. Energieträger in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus KWK, fossiler Brennstoff (Gasförmige und flüssige Brennstoffe) bzw. Energieträger in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus KWK, erneuerbarer Brennstoff bzw. Energieträger in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus Heizwerken, fossiler Brennstoff (Stein_/Braunkohle) bzw. Energieträger in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus Heizwerken, fossiler Brennstoff (Gasförmige und flüssige Brennstoffe) bzw. Energieträger in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus Heizwerken, erneuerbarer Brennstoff bzw. Energieträger in kWh");

    const presentDate = new Date();
    const presentYear = presentDate.getFullYear();

    // the newest bill end date should be maximally 18 months in the past
    const oldestBillYear = subMonths(presentDate, 18).getFullYear();

    for (let year = presentYear; year >= oldestBillYear; year--) {
      this.years.push(`${year}`);
    }
  }

  isValid(): boolean {
    return !(
      this.installationYearError ||
      this.heatingSystemError ||
      this.readingMonthError ||
      this.readingYearError ||
      this.readingValueError ||
      this.readingValueYearBeforeError ||
      this.readingValueTwoYearsBeforeError ||
      this.secondaryInstallationYearError ||
      this.secondaryReadingValueError ||
      this.secondaryReadingValueYearBeforeError ||
      this.secondaryReadingValueTwoYearsBeforeError
    );
  }

  validate(): boolean {
    this.installationYearError = !this.installationYear || Number.parseInt(this.installationYear) <= 0;
    this.heatingSystemError = !this.heatingSystem;
    this.readingMonthError = !this.readingMonth;
    this.readingYearError = !this.readingYear;
    this.readingValueError = !this.readingValue || Number.parseInt(this.readingValue) <= 0;
    this.readingValueYearBeforeError = !this.readingValueYearBefore || Number.parseInt(this.readingValueYearBefore) <= 0;
    this.readingValueTwoYearsBeforeError = !this.readingValueTwoYearsBefore || Number.parseInt(this.readingValueTwoYearsBefore) <= 0;

    this.secondaryInstallationYearError =
      Boolean(this.secondaryHeatingSystemType) && (!this.secondaryYearOfHeatingInstalation || Number.parseInt(this.secondaryYearOfHeatingInstalation) <= 0);
    this.secondaryReadingValueError =
      Boolean(this.secondaryHeatingSystemType) && (!this.secondaryReadingValue || Number.parseInt(this.secondaryReadingValue) <= 0);
    this.secondaryReadingValueYearBeforeError =
      Boolean(this.secondaryHeatingSystemType) && (!this.secondaryReadingValueYearBefore || Number.parseInt(this.secondaryReadingValueYearBefore) <= 0);
    this.secondaryReadingValueTwoYearsBeforeError =
      Boolean(this.secondaryHeatingSystemType) && (!this.secondaryReadingValueTwoYearsBefore || Number.parseInt(this.secondaryReadingValueTwoYearsBefore) <= 0);

    return this.isValid();
  }
}

export type HotWaterSystem = {
  type: string;
  isPartOfHeating?: boolean;
  partOfHeatingError?: boolean;
  isCentralized?: boolean;
  centralizedError?: boolean;
  error?: boolean;
};

class HotWaterData {
  systems: HotWaterSystem[];
  systemOptions: string[];
  error?: boolean;

  constructor(hotWaterSystemOptions: Array<string>) {
    this.systems = [];
    this.systemOptions = hotWaterSystemOptions;
  }

  isValid(): boolean {
    return !this.error && !this.systems.reduce((prev, curr) => prev || curr.partOfHeatingError || curr.centralizedError, false);
  }

  validate(): boolean {
    this.systems.forEach((system) => {
      system.partOfHeatingError = system.isPartOfHeating === undefined;
      system.centralizedError = !system.isPartOfHeating && system.isCentralized === undefined;
      system.error = system.partOfHeatingError || system.centralizedError;
    });
    this.error = this.systems.length === 0;
    return this.isValid();
  }
}
