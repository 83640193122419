import Grid from "@mui/material/Grid2";
import { SchnellcheckQuestionnaire } from "../../../../../../../types/Schnellcheck";
import { BubbleFlow } from "../../../../../../../types/cockpit/types";
import HouseTypeComponent from "./HouseTypeComponent";
import ConstructionYearComponent from "./ConstructionYearComponent";
import HouseSurfaceComponent from "./HouseSurfaceComponent";
import ListedBuildingComponent from "./ListedBuildingComponent";
import HeatingTypeComponent from "./HeatingTypeComponent";
import HeatingYearComponent from "./HeatingYearComponent";
import RoofInsulationYearComponent from "./RoofInsulationYearComponent";
import WallsInsulationYearComponent from "./WallsInsulationYearComponent";
import BasementInsulationYearComponent from "./BasementInsulationYearComponent";
import WindowTypeComponent from "./WindowTypeComponent";
import DoorYearComponent from "./DoorYearComponent";
import Report from "./Report";

export default function StatusQuoAnswers({ schnellcheck, bubbleFlow }: { schnellcheck?: SchnellcheckQuestionnaire; bubbleFlow?: BubbleFlow }) {
  if (!schnellcheck && !bubbleFlow) {
    return null;
  }
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 4,
      }}
      sx={{
        maxWidth: "800px",
      }}
    >
      <Grid size={12}>
        <HouseTypeComponent schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
      <Grid size={4}>
        <ConstructionYearComponent schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
      <Grid size={4}>
        <HouseSurfaceComponent schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
      <Grid size={4}>
        <ListedBuildingComponent schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
      <Grid size={12}>
        <HeatingTypeComponent schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
      <Grid size={6}>
        <HeatingYearComponent schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
      <Grid size={6}>
        <RoofInsulationYearComponent schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
      <Grid size={6}>
        <WallsInsulationYearComponent schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
      <Grid size={6}>
        <BasementInsulationYearComponent schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
      <Grid size={6}>
        <WindowTypeComponent schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
      <Grid size={6}>
        <DoorYearComponent schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
      <Grid size={12}>
        <Report schnellcheck={schnellcheck} bubbleFlow={bubbleFlow} />
      </Grid>
    </Grid>
  );
}
